import * as React from 'react'
import loadable from '@loadable/component'

const Select = loadable(() => import('/src/components/Form/Select'))

const SelectDay = ({ name, isSubmitting, isSubmitted, className }) => {
    const days = Array.from(Array(32).keys()).slice(1)

    return (
        <Select
            className={className}
            name={name}
            firstItem={'Select day'}
            data={days}
            isSubmitting={isSubmitting}
            isSubmitted={isSubmitted}
        />
    )
}

export default SelectDay
